<template>
<div class="my-4">
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <b-form inline>
          <span class="mr-2">
          <strong>Policies {{policies.length}} out of {{policiesAll.length}}</strong> |
          </span>
          <b-button class="mr-2" v-if="permissions.addPolicy" variant="outline-primary" :to="{ name: 'FormNew', params: { model: 'impactpolicy' }}">new policy</b-button>
          <b-button class="mr-2" v-if="permissions.addInitiative" variant="outline-primary" :to="{ name: 'FormNew', params: { model: 'impactinitiative' }}">new initiative</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col lg="3" class="py-3 px-0" style="background: white; height: 60vh; overflow-y: auto;  overflow-x: auto;">
        <div>
            <div class="mb-5" v-for="(row, index) in policiesByArea" :key="'topic-' + index">
              <h5 class="px-3">{{row.area}}</h5>
              <div class="px-3 py-3" v-for="(policy, i) in row.policies" :key="'policy-' + i" :id="'policy'+policy.id" @mouseover="findInitiatives(policy)">
                <b-link :to="{ name: 'ImpactPoliciesPolicy', params: { id: policy.id } }">{{policy.name}}</b-link>
              </div>
            </div>
        </div>
      </b-col>
      <b-col lg="3" v-if="showInitiatives" class="py-3 px-0" style="background: #e6f9ff; height: 60vh; overflow-y: auto;  overflow-x: auto;">
          <h5 class="px-3">{{ policy.name }}</h5>
          <div class="px-3 py-3" v-for="(initiative, index) in initiatives" :key="'initiative-' + index" :id="'initiative'+initiative.id" @mouseover="findInitiative(initiative)">
            <div>
              <router-link :to="{ name: 'ImpactPoliciesInitiative', params: { id: initiative.id }}">
                <div>{{initiative.country}}</div>
                <div><small>{{initiative.name}}</small></div>
              </router-link>
            </div>
          </div>
      </b-col>
      <b-col lg="6" v-if="initiative" class="py-3" style="background: white; height: 60vh; overflow-y: auto;  overflow-x: auto;">
          <h5><router-link :to="{ name: 'ImpactPoliciesInitiative', params: { id: initiative.id }}">{{ initiative.name }}</router-link></h5>
          <div><b-link :to="{ name: 'ImpactPoliciesCountry', params: { country: initiative.country } }">{{ initiative.country }}</b-link></div>
          <div v-if="initiative.impactexperts.length > 0">
          <div class="mt-4"><strong>Experts</strong></div>
          <div v-for="expert in initiative.impactexperts" :key="'expert'+expert.id">
            <div v-if="$config.BUILD === 'full'">
              <router-link :to="{ name: 'Expert', params: { id: expert.id }}">{{expert.name}}</router-link>
            </div>
            <div v-else>{{expert.name}}</div>
          </div>
          </div>
          <div class="my-4"><strong>{{ initiative.headline }}</strong></div>
          <div v-html="initiative.description"></div>
          <div v-if="initiative.impactpolicy_id > 0">
            <policy-initiative-details :item="initiative" />
          </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import PolicyInitiativeDetails from '@/components/PolicyInitiativeDetails'

export default {
  name: 'PoliciesList',
  components: {
    PolicyInitiativeDetails
  },
  computed: {
    areas: function () {
      const policiesAll = this.policiesAll
      let results = ['General']
      policiesAll.forEach(policy => {
        if (policy.taxonomy.length > 0) {
          policy.taxonomy.forEach(taxonomy => {
            results.push(taxonomy.name)
          })
        }
      })
      results = _.uniq(results)
      results.sort()
      return results
    },
    initiativesAll: function () {
      return this.$store.state.initiativesPolicies
    },
    policies: function () {
      return this.$store.getters.policiesShow
    },
    policiesAll: function () {
      return this.$store.state.policies
    },
    policiesByArea: function () {
      const results = []
      const all = this.policies
      this.areas.forEach(area => {
        let row = {}
        row = {
          area: area,
          policies: []
        }
        all.forEach(policy => {
          if (policy.taxonomy.length > 0) {
            policy.taxonomy.forEach(t => {
              if (t.name === area) {
                row.policies.push(policy)
              }
            })
          } else {
            if (area === 'General') {
              row.policies.push(policy)
            }
          }
        })
        if (row.policies.length > 0) {
          results.push(row)
        }
      })
      return results
    },
    policiesByTopic: function () {
      const results = []
      const all = this.policies
      this.topics.forEach(topic => {
        let row = {}
        row = {
          topic: topic,
          policies: []
        }
        all.forEach(policy => {
          if (policy.topics.length > 0) {
            policy.topics.forEach(t => {
              if (t.name === topic) {
                row.policies.push(policy)
              }
            })
          } else {
            if (topic === 'General') {
              row.policies.push(policy)
            }
          }
        })
        if (row.policies.length > 0) {
          results.push(row)
        }
      })
      return results
    },
    topics: function () {
      const policiesAll = this.policiesAll
      let results = ['General']
      policiesAll.forEach(policy => {
        if (policy.topics.length > 0) {
          policy.topics.forEach(topic => {
            results.push(topic.name)
          })
        }
      })
      results = _.uniq(results)
      results.sort()
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Climate Policy and Regulatory Dashboard - Policies"
    this.$stat.log({ page: 'impactpolicies list', action: 'open impactpolicies list' })
    this.permissions.addInitiative = ac.can(this.user.acgroups).createAny('impactinitiative').granted
    this.permissions.addPolicy = ac.can(this.user.acgroups).createAny('impactpolicy').granted
  },
  data () {
    const data = {
      initiative: null,
      initiatives: [],
      permissions: {
        addInitiative: false,
        addPolicy: false
      },
      policy: null,
      showInitiatives: false
    }
    return data
  },
  methods: {
    findInitiative: function (initiative) {
      if (this.initiative) {
        document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #e6f9ff;')
      }
      this.initiative = initiative
      document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #aceaff;')
    },
    findInitiatives: function (policy) {
      if (this.policy) {
        document.getElementById('policy' + this.policy.id).setAttribute('style', 'background-color: white;')
      }
      if (this.initiative) {
        document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #e6f9ff;')
      }
      this.initiative = null
      this.showInitiatives = false
      this.policy = policy
      this.initiatives = _.sortBy(_.filter(this.initiativesAll, { impactpolicy_id: policy.id }), ['country'])
      document.getElementById('policy' + this.policy.id).setAttribute('style', 'background-color: #aceaff;')
      this.showInitiatives = true
    }
  }
}
</script>

<style>
</style>
